import { Divider, Grid, Typography, Toolbar } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import React from "react";
import { AppBar } from "@mui/material";
import { IconButton } from "@mui/material";
import { Palette } from "@mui/icons-material";
import logoC from '../../assets/logos/logoC.jpg';

function Footer() {
  return (
    <AppBar
      position="static"
      elevation={0}
      style={{ backgroundColor: "white", bottom: 0, top: "auto" }}
    >
      <Toolbar>
        {/* <PhoneForwardedIcon fontSize="large" /> */}
        <img src={logoC} style={{ height: 30 }} />
        <Typography
          sx={{
            color: "black",
            flexGrow: 1,
            display: "flex",
            fontWeight: "bold"
          }}
        >
          {"Ministerio de Minería"}
        </Typography>
        <IconButton onClick={() => { window.open("https://twitter.com/catamarcaminera") }}>
          <TwitterIcon />
        </IconButton>
        <IconButton onClick={() => { window.open("https://www.facebook.com/catamarcaminera/") }}>
          <FacebookIcon />
        </IconButton>
        <IconButton onClick={() => { window.open("https://www.youtube.com/catamarcaminera/") }}>
          <YouTubeIcon />
        </IconButton>
      </Toolbar>
    </AppBar >
  );
}

export default Footer;
