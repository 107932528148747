import { ArrowBackIos, Close, Download, ReadMore } from "@mui/icons-material";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Divider,
    Grid,
    Link,
    Typography,
    Dialog,
    DialogTitle,
    IconButton,
    Fab,
    Box,
    AppBar,
    Toolbar,
} from "@mui/material";
import React from "react";
import PdfViewer from "./PdfViewer";

//const img = require('../../assets/imagenes/Image2022-1.jpeg');

//Temporarily store data here
interface Post {
    titleES: string;
    titleEN: string;
    textES: string;
    textEN: string;
    image: any;
    pdfES?: any;
    pdfEN?: any;
    url?: string;
}

const postsData: Array<Post> = [
    /*{
        image: require("../../assets/imagenes/oportunidad.png"),
        titleES: "Oportunidad de Inversion",
        textES: "Conozca el potencial minero de la provincia",
        pdfES: require("../../assets/pdfs/CATAMARCAINVERSION.pdf"),
        titleEN: "An investment opportunity",
        textEN: "Get to know the mining potential in the province",
        pdfEN: require("../../assets/pdfs/INVESTMENT.pdf"),
    },*/
    {
        image: require("../../assets/imagenes/Image2022-1.jpeg"),
        titleES: "Litio en Catamarca",
        textES: "Concesiones de Litio en Catamarca",
        pdfES: require("../../assets/pdfs/lithium_es.pdf"),
        titleEN: "Lithium in Catamarca",
        textEN: "Concession of Lithiun in Catamarca",
        pdfEN: require("../../assets/pdfs/lithium_en.pdf"),
    },
    // {
    //     image: require("../../assets/imagenes/Image2022-2.jpeg"),
    //     titleES: "Concesiones Metalíferas y no Metalíferas",
    //     textES: "Concesiones Metalíferas y no Metalíferas",
    //     pdfES: require("../../assets/pdfs/METALIFERAS.pdf"),
    //     titleEN: "Metal and Non-Metal Concessions",
    //     textEN: "Deposits mining Aguas de Dionisio",
    //     pdfEN: require("../../assets/pdfs/METALIFERAS.pdf"),
    // },
    {
        image: require("../../assets/imagenes/Image2022-4.jpeg"),
        titleES: "CAMYEN S.E",
        textES: "Catamarca Minera y Energética Sociedad del Estado ",
        pdfES: require("../../assets/pdfs/CAMYEN.pdf"),
        titleEN: "CAMYEN S.E",
        textEN: "Catamarca Mining and Energy State Company ",
        pdfEN: require("../../assets/pdfs/CAMYEN.pdf"),
    },
    /*{
        image: require("../../assets/imagenes/Image2022.jpeg"),
        titleES: "Cerro Atajo",
        textES: "Conocé el potencial del proyecto Cerro Atajo",
        pdfES: require("../../assets/pdfs/CerroAtajo.pdf"),
        titleEN: "Cerro Atajo",
        textEN: "Learn the potential of the Cerro Atajo project",
        pdfEN: require("../../assets/pdfs/CerroAtajo.pdf"),
    },*/
    {
        image: require("../../assets/imagenes/Image2022-3.jpeg"),
        titleES: "Areas de Interés Geológicas Mineras",
        textES: "Conocé nuestro potencial geológico minero",
        pdfES: require("../../assets/pdfs/AREASMINERAS.pdf"),
        titleEN: "Geological Mining areas of interest",
        textEN: "Learn about our geological mining potential",
        pdfEN: require("../../assets/pdfs/AREASMINERAS.pdf"),
    },
    {
        image: require("../../assets/imagenes/Kotra/ymad.png"),
        titleES: "Ymad",
        textES: "Nuevos esquemas de fomento y desarrollo minero en Catamarca",
        titleEN: "Ymad",
        textEN: "New schemes of promotion and mining development in Catamarca",
        url: "http://www.ymad.com.ar",
    },
    {
        image: require("../../assets/imagenes/Kotra/bioceanico.png"),
        titleES: "Corredor bioceanico",
        textES: "Nuevos esquemas de fomento y desarrollo minero en Catamarca",
        pdfES: require("../../assets/pdfs/bioceanic_es.pdf"),
        titleEN: "Bioceanic Corridor",
        textEN: "New schemes of promotion and mining development in Catamarca",
        pdfEN: require("../../assets/pdfs/bioceanic_en.pdf"),
    },
    /*{
        image: require("../../assets/imagenes/Image2022-5.jpeg"),
        titleES: "Fomento y Desarrollo",
        textES: "Nuevos esquemas de fomento y desarrollo minero en Catamarca",
        pdfES: require("../../assets/pdfs/NUEVOSESQUEMAS.pdf"),
        titleEN: "Promotion and mining development",
        textEN: "New schemes of promotion and mining development in Catamarca ",
        pdfEN: require("../../assets/pdfs/NUEVOSESQUEMAS.pdf"),
    },*/
];

interface OwnProps {
    lang: "ES" | "EN";
    // setPdf: (newPdf: any) => void;
}

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
console.log('soy mobile:', isMobile, navigator.userAgent)
function Cards(props: OwnProps) {
    const [dialogPdf, setDialogPdf] = React.useState<any>(null);
    const { lang } = props;
    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                }}
            >
                <Typography variant="h5" sx={{ fontWeight: "bold", marginLeft: 5, marginRight: 5, marginTop: 5 }}>
                    {lang == "ES" ? "Informate más acerca de Catamarca Minera" : "Learn more about Mining in Catamarca"}
                </Typography>
            </div>
            <Divider />
            <div style={{ marginTop: "50px", marginBottom: "50px" }}>
                <div
                    style={{
                        marginTop: "50px",
                        marginBottom: "50px",
                        marginRight: "5%",
                        marginLeft: "5%",
                    }}
                >
                    <Grid container alignItems="center" direction="row" spacing={3}>
                        {postsData.map((post, iPost) => {
                            const title = lang == "ES" ? post.titleES : post.titleEN;
                            const text = lang == "ES" ? post.textES : post.textEN;
                            const postPdf = lang == "ES" ? post.pdfES : post.pdfEN;
                            return (
                                <Grid item xs={12} md={6} xl={3} key={iPost} style={{ minHeight: "100%" }}>
                                    <Card elevation={3} style={{ borderRadius: 0, minHeight: "100%" }}>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={post.image}
                                        />
                                        <CardHeader sx={{ minHeight: 50 }} title={
                                            <Typography sx={{ fontWeight: "bold" }}>
                                                {title}
                                            </Typography>
                                        } />
                                        <CardContent sx={{ minHeight: 50 }}>{text}</CardContent>
                                        <CardActions>
                                            <Button
                                                size="small"
                                                onClick={(event: { preventDefault: () => void }) => {

                                                    if (post.url) {
                                                        window.open(post.url);
                                                    } else {
                                                        setDialogPdf(dialogPdf);
                                                        event.preventDefault();
                                                        if (isMobile) {
                                                            //ACA IRIA LO DE MOBILE
                                                            setDialogPdf(postPdf)
                                                            // let url = `http://docs.google.com/gview?embedded=true&url=${window.location.host}${pdf}`
                                                            // window.open(url);
                                                        } else {
                                                            window.open(postPdf);
                                                        }
                                                    }
                                                }}
                                                variant="contained"
                                                color="primary"
                                                startIcon={< ReadMore />}
                                                disableElevation
                                                sx={{ borderRadius: 100 }}
                                            >
                                                {lang == "ES" ? "Ver más" : "Read more"}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
            </div>

            {/* <Fab onClick={() => { setDialogPdf(null) }} style={{ position: "fixed", top: 10, right: 10, display: dialogPdf == null ? "none" : "block", zIndex: 999 }}><Close /></Fab> */}
            <AppBar style={{ position: "fixed", top: 0, zIndex: 999, backgroundColor: "#DDDDDD", display: dialogPdf == null ? "none" : "block" }}>
                <Toolbar variant="dense" >
                    <Button onClick={() => { setDialogPdf(null) }} size="small" variant="contained" disableElevation style={{ borderRadius: 20 }} startIcon={<ArrowBackIos />}>
                        Volver
                    </Button>
                    <div style={{ display: "flex", flexGrow: 1 }} />
                    <Button onClick={() => { window.open(dialogPdf) }} size="small" variant="contained" disableElevation style={{ borderRadius: 20 }} startIcon={<Download />} >
                        Descargar
                    </Button>
                </Toolbar>
            </AppBar>
            <Dialog open={dialogPdf != null} onClose={() => { setDialogPdf(null) }} fullScreen style={{ zIndex: 998 }}>
                <PdfViewer lang={lang} pdf={dialogPdf} />
            </Dialog>
        </>
    );
}

export default Cards;
