import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { IconButton, Button, Typography, AppBar, Box, Toolbar, InputBase } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import mainLogo from '../../assets/logos/logo-mineria.png';
import FlagIconAR from '../../assets/flags/espana.png';
import FlagIconUS from '../../assets/flags/reino-unido.png';


const StyledButton = styled(Button)(({ theme }) => ({
  margin: 10,
  borderRadius: "40px",
  '&:hover': {
    backgroundColor: alpha(theme.palette.info.main, 0.25),
  },
}));


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

interface OwnProps {
  lang: "ES" | "EN";
  setLang: (newLang: "EN" | "ES") => void;
}


const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
console.log('soy mobile:', isMobile, navigator.userAgent)
export default function SearchAppBar(props: OwnProps) {
  const { lang, setLang } = props;
  const [dialogPdf, setDialogPdf] = React.useState<any>(null);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "#DDDDDD" }}>
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: "flex", align: "center" }}
          >
            MINISTERIO DE MINERIA
          </Typography> */}
          <img style={{ height: 30 }} src={mainLogo} />
          <div style={{ display: "flex", flexGrow: 1 }} />
          <div style={{ paddingRight: 2 }}>
            <StyledButton variant="contained" onClick={() => { setLang("ES") }} >
              {/* <FlagIconAR /> */}
              ESP
              {/* <img style={{ height: 20 }} src={FlagIconAR} /> */}
              {/* <FlagIconAR /> */}
            </StyledButton>
            <StyledButton variant="contained" onClick={() => { setLang("EN") }} >
              ENG
              {/* <img style={{ height: 20 }} src={FlagIconUS} /> */}
              {/* <FlagIconUS /> */}
            </StyledButton>

          </div>

          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Buscar…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}