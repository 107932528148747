import React from 'react'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import MyVideo from "../../assets/videos/videoCanada.mp4";
import { Divider, Grid, Typography, Box } from '@mui/material';
import { Title } from '@mui/icons-material';
import ReactPlayer from "react-player";



interface OwnProps {
  lang: "EN" | "ES";
  url: string;
  title:string;
}

function Video(props: OwnProps) {

  const { lang, url,title } = props;
  
  return (<>
    <Divider />
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
        marginTop: "20px",
        marginBottom: "20px",
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: "bold", marginLeft: 5, marginRight: 5 }}>
        {/* {lang == "ES" ? "Potencial Geológico Minero" : "Mining Geological Potential"} */}
        {title}
      </Typography>
    </div>
    <Divider />
    <div style={{ display: "flex", flexGrow: 1, justifyContent: 'center', marginTop: '50px', marginBottom: '50px' }}>
      <Grid container >
        <Grid item xs={1} md={0} />
        <Grid item xs>
          <Box display="flex" justifyContent="center" alignItems="center" >
            <ReactPlayer style={{}} url={url} controls={true} />
          </Box>
        </Grid>
        <Grid item xs={1} md={0} />
      </Grid>
    </div>
  </>
  )
}

export default Video