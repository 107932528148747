import { createTheme } from "@mui/material";

const font =  "Montserrat, sans-serif";

export default function createThemeWeb() {
    return createTheme({
        palette: {
            primary: {
                light: '#fefefe',
                main: '#1c5f83',
                dark: '#986a46',
                contrastText: '#ffffff'
            },
            secondary: {
                light: '#5cbddd',
                main: '#3c765f',
                dark: '#f3d6a8',
                contrastText: '#212121'
            },
            
        },
        typography: {
            fontFamily: font,
        },
    }
    )
}

// XS: 320 a 575 (elastico)
// SM: 576 a 767
// ML: 768 a 1023
// L: 1024 a 1199
// XL: 1200  en adelante