import React from "react"
import PdfViewer from "../componentes/components/PdfViewer";
import Cards from "../componentes/components/Cards"
import NavBar from "../componentes/components/NavBar"
import Video from "../componentes/components/Video"
import Footer from "../componentes/components/Footer"
import { Button, Dialog, Grid } from "@mui/material"
import ScrollToTop from "../componentes/components/ScrollToTop";
import mainLogoKotra from '../assets/logos/logo_kotra.jpg'
import TopCards from '../componentes/components/TopCards';
import { Link } from 'react-router-dom';
import useWindowDimensions from "../componentes/hooks/useWindowDimensions";

const URLVIDEO = 'https://youtu.be/NGV5AIowDQA'
const URLVIDEOINGLES = 'https://youtu.be/ON3gsfrByGk'

const URLVIDEO35 = 'https://youtu.be/tj_BtbBasys'
const URLVIDEO35INGLES = 'https://youtu.be/rugfuwSh35o'

interface OwnProps {
    lang: "ES" | "EN";
    setLang: (newLang: "EN" | "ES") => void;
}

export const Home = (props: OwnProps) => {

    const { lang, setLang } = props;

    const [dialogPdf, setDialogPdf] = React.useState<any>(null);
    const mainPdf = require(lang == "ES" ? '../assets/pdfs/main_es.pdf' : '../assets/pdfs/main_en.pdf');
    const windowSize = useWindowDimensions();

    return (
        <>
            <NavBar lang={lang} setLang={setLang} />
            <br />


            <Grid container>

                <Grid item xs={0} md={1} lg={1.5} />

                <Grid item xs>
                    {/* <TopCards /> */}
                    <Link to="/Presentation">
                        <Button
                            color="primary"
                            variant="outlined"
                            disableElevation
                            style={
                                windowSize.width > 800 ?
                                    {
                                        width: "calc(100vw - 160px)",
                                        marginLeft: "80px",
                                        marginRight: "80px",
                                        height: "150px",
                                        borderRadius: "100px"
                                    }
                                    :
                                    {
                                        width: "calc(100vw - 60px)",
                                        marginLeft: "30px",
                                        marginRight: "30px",
                                        height: "150px",
                                        borderRadius: "100px"
                                    }
                            }
                        >
                            <img
                                style={
                                    windowSize.width > 800 ?
                                        { height: 140 }
                                        :
                                        { width: "80vw" }
                                }
                                alt="PDAC"
                                src={require('../assets/imagenes/button.png')}
                            />
                            {/* {lang == "ES" ?
                                "Presentacion del Ministro"
                                :
                                "The Minister's Presentation"
                            } */}
                        </Button>
                    </Link>
                    <PdfViewer lang={lang} pdf={mainPdf} />
                    <Video lang={lang} url={lang == "ES" ? URLVIDEO35 : URLVIDEO35INGLES} title={lang == "ES" ? "Aporte de las empresas mineras en Catamarca" : "Contribution of mining companies in Catamarca"} />
                    <Cards lang={lang} />
                    <Video lang={lang} url={lang == "ES" ? URLVIDEO : URLVIDEOINGLES} title={lang == "ES" ? "Potencial Geológico Minero" : "Mining Geological Potential"} />
                    <Dialog open={dialogPdf != null} onClose={() => { setDialogPdf(null) }} fullScreen style={{ zIndex: 998 }}>
                        <PdfViewer lang={lang} pdf={dialogPdf} />
                    </Dialog>
                    <ScrollToTop />
                </Grid>
                <Grid item xs={0} md={1} lg={1.5} />
            </Grid>
            <Footer />
        </>
    )

}
export default Home as React.ComponentType