import React from "react";
import './App.css';
import createThemeWeb from './theme/AppTheme';
import { ThemeProvider } from "@mui/material";
import { Home } from './pages/home';
import ScrollToTop from "./componentes/components/ScrollToTop";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import SantaRita from './pages/SantaRita';
import { Provider } from "react";
import Kotra from './pages/kotra';
import Presentation from "./pages/presentation";

const theme = createThemeWeb();

function App() {
  // window.location.replace('https://portal.catamarca.gob.ar/ui/paginas/portal-minero-254');
  const [lang, setLang] = React.useState<"EN" | "ES">("ES");
  return (
    <>
      <ThemeProvider theme={theme}>
        <div id="skewed_bg_1" className="skewed_bg" />
        <div className="App">
          <ScrollToTop />
          <div className="section section1"></div>
          <div className="section section2"></div>
          <div className="section section3"></div>
          <div className="section section4"></div>
          <div className="section section5"></div>
        </div>
        <Router>
          <Routes>
            <Route path='/' element={<Home setLang={setLang} lang={lang} />} />
            <Route path='SantaRita' element={<SantaRita />} />
            <Route path='Kotra' element={<Kotra />} />
            <Route path='Presentation' element={<Presentation lang={lang} />} />
          </Routes>
        </Router>
      </ThemeProvider>

    </>
  );

}

export default App;
