import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import React from "react";

// import pdfInversionES from '../../assets/pdfs/CATAMARCAINVERSION.pdf';
// import pdfInversionEN from '../../assets/pdfs/CATAMARCAINVERSION.pdf';
// import pdfLitioES from '../../assets/pdfs/CATAMARCAINVERSION.pdf';
// import pdfLitioEN from '../../assets/pdfs/CATAMARCAINVERSION.pdf';
// import pdfAreasMinerasES from '../../assets/pdfs/CATAMARCAINVERSION.pdf';
// import pdfCamyenES from '../../assets/pdfs/CATAMARCAINVERSION.pdf';
// import pdfCerroAtajoES from '../../assets/pdfs/CATAMARCAINVERSION.pdf';
// import pdfMetaliferasES from '../../assets/pdfs/CATAMARCAINVERSION.pdf';

import { Grid } from "@mui/material";
import useWindowDimensions from "../hooks/useWindowDimensions";

// const pdf = require('../../assets/pdfs/CATAMARCAINVERSION.pdf')

interface Post {
    nombre: string;
    pdfES: any;
    pdfEN: any;
}

interface OwnProps {
    lang: "ES" | "EN";
    pdf: any;
}

function PdfViewer(props: OwnProps) {
    const { lang, pdf } = props;

    const [numPages, setNumPages] = React.useState<number | null>(null);
    const [pageNumber, setPageNumber] = React.useState(1);

    const { width: windowWidth, height: windowHeight } = useWindowDimensions();


    const onDocumentLoadSuccess = (val: { numPages: number }) => {
        setNumPages(val.numPages);
    }

    return (
        <div style={{ margin: "20px 0px 20px 0px" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.from(
                        new Array(numPages),
                        (el, index) => (
                            <>
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    width={Math.max(windowWidth * 0.85, (windowWidth * 0.9) - 10)}
                                    scale={1}
                                />
                                {/* <div style={{ height: 10 }} /> */}
                            </>
                        ),
                    )}
                </Document>
            </div>
            {/* <img style={{ width: "100%" }} src={imagen1ES} />
            <img style={{ width: "100%" }} src={imagen2ES} />
            <img style={{ width: "100%" }} src={imagen3ES} />
            <img style={{ width: "100%" }} src={imagen4ES} />
            <img style={{ width: "100%" }} src={imagen5ES} />
            <img style={{ width: "100%" }} src={imagen6ES} />
            <img style={{ width: "100%" }} src={imagen7ES} /> */}
        </div>
    )
}

export default PdfViewer;
