import { ArrowBackIos, Close, Download, ReadMore } from "@mui/icons-material";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Divider,
    Grid,
    Link,
    Typography,
    Dialog,
    DialogTitle,
    IconButton,
    Fab,
    Box,
    AppBar,
    Toolbar,
} from "@mui/material";

import React from "react";
import PdfViewer from "../componentes/components/PdfViewer";


interface Post {
    titleES: string;
    titleEN: string;
    textES: string;
    textEN: string;
    image: any;
    pdfES: any;
    pdfEN: any;
}

const postsData: Array<Post> = [
    {
        image: require("../assets/imagenes/SantaRita/aquabor.png"),
        titleES: "",
        textES: "",
        pdfES: require("../assets/pdfs/SantaRita/Aquabor-IN.pdf"),
        titleEN: "Aquabor",
        textEN: "",
        pdfEN: require("../assets/pdfs/SantaRita/Aquabor-IN.pdf"),
    },
    {
        image: require("../assets/imagenes/SantaRita/boro-10-msr.png"),
        titleES: "",
        textES: "",
        pdfES: require("../assets/pdfs/METALIFERAS.pdf"),
        titleEN: "Boro 10",
        textEN: "",
        pdfEN: require("../assets/pdfs/SantaRita/Boro-10-MSR-IN.pdf"),
    },
    {
        image: require("../assets/imagenes/SantaRita/acidoBorico.png"),
        titleES: "",
        textES: "",
        pdfES: require("../assets/pdfs/AREASMINERAS.pdf"),
        titleEN: "Ácido Bórico",
        textEN: "",
        pdfEN: require("../assets/pdfs/SantaRita/AcidoBorico-IN.pdf"),
    },
    {
        image: require("../assets/imagenes/SantaRita/boro15.png"),
        titleES: "",
        textES: "",
        pdfES: require("../assets/pdfs/NUEVOSESQUEMAS.pdf"),
        titleEN: "Boro 15",
        textEN: "",
        pdfEN: require("../assets/pdfs/SantaRita/Boro15-IN.pdf"),
    },
    //  {
    //     image: require("../assets/imagenes/Image2022-5.jpeg"),
    //     titleES: "Fomento y Desarrollo",
    //     textES: "Nuevos esquemas de fomento y desarrollo minero en Catamarca",
    //     pdfES: require("../assets/pdfs/NUEVOSESQUEMAS.pdf"),
    //     titleEN: "Boro 10 IN",
    //     textEN: "",
    //     pdfEN: require("../assets/pdfs/SantaRita/Boro10-IN.pdf"),
    // },
    {
        image: require("../assets/imagenes/SantaRita/borax10.png"),
        titleES: "",
        textES: "",
        pdfES: require("../assets/pdfs/NUEVOSESQUEMAS.pdf"),
        titleEN: "Borax 10",
        textEN: "",
        pdfEN: require("../assets/pdfs/SantaRita/Borax10-IN.pdf"),
    },
    // {
    //     image: require("../assets/imagenes/SantaRita/aquabor2.png"),
    //     titleES: "",
    //     textES: "",
    //     pdfES: require("../assets/pdfs/NUEVOSESQUEMAS.pdf"),
    //     titleEN: "Aquabor 2",
    //     textEN: "",
    //     pdfEN: require("../assets/pdfs/SantaRita/Borax10-IN.pdf"),
    // },
];

interface OwnProps {
    // lang: "ES" | "EN";
    setPdf: (newPdf: any) => void;
}

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
console.log('soy mobile:', isMobile, navigator.userAgent)
export const SantaRita = (props: OwnProps) => {

    const [dialogPdf, setDialogPdf] = React.useState<any>(null);
    const [lang, setLang] = React.useState<"EN" | "ES">("EN");
    const mainPdf = require(lang == "EN" ? '../assets/pdfs/VVLCompletepresentation.pdf' : '../assets/pdfs/VVLCompletepresentation.pdf');

    return (
        <>
            <PdfViewer lang={lang} pdf={mainPdf} />
            <CardActions>
                            <Button
                                size="small"
                                onClick={(event: { preventDefault: () => void }) => {
                                    setDialogPdf(dialogPdf);
                                    event.preventDefault();
                                    window.open("http://www.santaritasrl.com");
                                    
                                }}
                                variant="contained"
                                color="primary"
                                startIcon={< ReadMore />}
                                disableElevation
                                sx={{ borderRadius: 100 }}
                            >
                                {lang == "ES" ? "Ver más" : "Read more"}
                            </Button>
            </CardActions>
            <div
                style={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                }}
            >
                <Typography variant="h5" sx={{ fontWeight: "bold", marginLeft: 5, marginRight: 5, marginTop: 5 }}>
                    {lang == "ES" ? "Informate más acerca de Catamarca Minera" : "Learn more about Mining in Catamarca"}
                </Typography>
            </div>
            <Divider />
            <div style={{ marginTop: "50px", marginBottom: "50px" }}>
                <div
                    style={{
                        marginTop: "50px",
                        marginBottom: "50px",
                        marginRight: "5%",
                        marginLeft: "5%",
                    }}
                >
                    <Grid container alignItems="center" direction="row" spacing={3}>
                        {postsData.map((post, iPost) => {
                            const title = lang == "ES" ? post.titleES : post.titleEN;
                            const text = lang == "ES" ? post.textES : post.textEN;
                            const postPdf = lang == "ES" ? post.pdfES : post.pdfEN;
                            return (
                                <Grid item xs={12} md={6} xl={3} key={iPost}>
                                    <Card elevation={3} style={{ borderRadius: 0 }}>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={post.image}
                                        />
                                        <CardHeader sx={{ minHeight: 50 }} title={
                                            <Typography sx={{ fontWeight: "bold" }}>
                                                {title}
                                            </Typography>
                                        } />
                                        <CardContent sx={{ minHeight: 50 }}>{text}</CardContent>
                                        <CardActions>
                                            <Button
                                                size="small"
                                                onClick={(event: { preventDefault: () => void }) => {
                                                    setDialogPdf(dialogPdf);
                                                    event.preventDefault();
                                                    if (isMobile) {
                                                        setDialogPdf(postPdf)
                                                    } else {
                                                        window.open(postPdf);
                                                    }
                                                }}
                                                variant="contained"
                                                color="primary"
                                                startIcon={< ReadMore />}
                                                disableElevation
                                                sx={{ borderRadius: 100 }}
                                            >
                                                {lang == "ES" ? "Ver más" : "Read more"}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>

                            );
                        })}
                        
                    </Grid>
                </div>
            </div>

             {/* <Fab onClick={() => { setDialogPdf(null) }} style={{ position: "fixed", top: 10, right: 10, display: dialogPdf == null ? "none" : "block", zIndex: 999 }}><Close /></Fab> */}
             <AppBar style={{ position: "fixed", top: 0, zIndex: 999, backgroundColor: "#DDDDDD", display: dialogPdf == null ? "none" : "block" }}>
                <Toolbar variant="dense" >
                    <Button onClick={() => { setDialogPdf(null) }} size="small" variant="contained" disableElevation style={{ borderRadius: 20 }} startIcon={<ArrowBackIos />}>
                        Volver
                    </Button>
                    <div style={{ display: "flex", flexGrow: 1 }} />
                    <Button onClick={() => { window.open(dialogPdf) }} size="small" variant="contained" disableElevation style={{ borderRadius: 20 }} startIcon={<Download />} >
                        Descargar
                    </Button>
                </Toolbar>
            </AppBar>
            <Dialog open={dialogPdf != null} onClose={() => { setDialogPdf(null) }} fullScreen style={{ zIndex: 998 }}>
                <PdfViewer lang={lang} pdf={dialogPdf} />
            </Dialog>

        </>
    )

}
export default SantaRita as React.ComponentType