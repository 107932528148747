import { ArrowBackIos, Close, Download, ReadMore } from "@mui/icons-material";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Divider,
    Grid,
    Link,
    Typography,
    Dialog,
    DialogTitle,
    IconButton,
    Fab,
    Box,
    AppBar,
    Toolbar,
} from "@mui/material";
import React from "react";
import PdfViewer from "../componentes/components/PdfViewer";
import mainLogo from '../assets/logos/logo-mineria.png';

//const img = require('../../assets/imagenes/Image2022-1.jpeg');

//Temporarily store data here
interface Post {
    titleES: string;
    titleEN: string;
    textES: string;
    textEN: string;
    image: any;
    pdfES?: any;
    pdfEN?: any;
    url?: string;
}

const postsData: Array<Post> = [
    {
        image: require("../assets/imagenes/presentation.png"),
        titleES: "Presentacion del Ministro",
        textES: "Presentacion realizada por el ministro de Mineria",
        pdfES: require("../assets/pdfs/presentation_es.pdf"),
        titleEN: "Minister's Presentation",
        textEN: "Presentation made by the Mining Minister",
        pdfEN: require("../assets/pdfs/presentation_en.pdf"),
    },
];
interface OwnProps {
    lang: "ES" | "EN";
    // setPdf: (newPdf: any) => void;
}

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
console.log('soy mobile:', isMobile, navigator.userAgent)
function Presentation(props: OwnProps) {
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
    const { lang } = props;
    const presentation = lang === "ES" ? postsData[0].pdfES : postsData[0].pdfEN;
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" sx={{ backgroundColor: "#DDDDDD" }}>
                    <Toolbar>

                        <img style={{ height: 30 }} src={mainLogo} />
                        <div style={{ display: "flex", flexGrow: 1 }} />

                    </Toolbar>
                </AppBar>
            </Box>
            <div
                style={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                }}
            >
                {/* <Typography variant="h5" sx={{ fontWeight: "bold", marginLeft: 5, marginRight: 5, marginTop: 5 }}>
                    {lang == "ES" ? "Presentacion del Ministro" : "Learn more about Mining in Catamarca"}
                </Typography> */}
            </div>
            <img style={{ width: "80vw", marginLeft: "10vw", marginRight: "10vw" }} alt="pdac" src={require('../assets/imagenes/pdac.jpeg')} />
            <Divider />
            <div style={{ marginTop: "50px", marginBottom: "50px" }}>
                <div
                    style={{
                        marginTop: "50px",
                        marginBottom: "50px",
                        marginRight: "5%",
                        marginLeft: "5%",
                    }}
                >
                    <Grid container alignItems="center" direction="row" spacing={3}>
                        {postsData.map((post, iPost) => {
                            const title = lang == "ES" ? post.titleES : post.titleEN;
                            const text = lang == "ES" ? post.textES : post.textEN;
                            const postPdf = lang == "ES" ? post.pdfES : post.pdfEN;
                            return (
                                <Grid item xs={12} md={6} xl={3} key={iPost} style={{ minHeight: "100%" }}>
                                    <Card elevation={3} style={{ borderRadius: 0, minHeight: "100%" }}>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={post.image}
                                        />
                                        <CardActions>
                                            <Button
                                                size="small"
                                                onClick={(event: { preventDefault: () => void }) => {

                                                    event.preventDefault();
                                                    if (isMobile) {
                                                        //ACA IRIA LO DE MOBILE
                                                        setDialogOpen(true)
                                                        // let url = `http://docs.google.com/gview?embedded=true&url=${window.location.host}${pdf}`
                                                        // window.open(url);
                                                    } else {
                                                        window.open(postPdf);
                                                    }
                                                }}
                                                variant="contained"
                                                color="primary"
                                                startIcon={< ReadMore />}
                                                disableElevation
                                                sx={{ borderRadius: 100 }}
                                            >
                                                {lang == "ES" ? "Ver más" : "Read more"}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
            </div>

            {/* <Fab onClick={() => { setDialogPdf(null) }} style={{ position: "fixed", top: 10, right: 10, display: dialogPdf == null ? "none" : "block", zIndex: 999 }}><Close /></Fab> */}
            <AppBar style={{ position: "fixed", top: 0, zIndex: 999, backgroundColor: "#DDDDDD", display: dialogOpen ? "block" : "none" }}>
                <Toolbar variant="dense" >
                    <Button onClick={() => { setDialogOpen(false) }} size="small" variant="contained" disableElevation style={{ borderRadius: 20 }} startIcon={<ArrowBackIos />}>
                        Volver
                    </Button>
                    <div style={{ display: "flex", flexGrow: 1 }} />
                    <Button onClick={() => { window.open(presentation) }} size="small" variant="contained" disableElevation style={{ borderRadius: 20 }} startIcon={<Download />} >
                        Descargar
                    </Button>
                </Toolbar>
            </AppBar>
            <Dialog open={dialogOpen} onClose={() => { setDialogOpen(false) }} fullScreen style={{ zIndex: 998 }}>
                <PdfViewer lang={lang} pdf={presentation} />
            </Dialog>
        </>
    );
}

export default Presentation;
